/* globals logLevels */
import Logger from '../utils/logger/logger'
import broadcast from '../utils/subscription_utils/broadcast'
import { assign, createMachine, sendParent } from 'xstate'

export const checkboxDefinition = {
  id: 'checkbox',
  initial: 'initial',
  context: {
    id: null,
    groupKey: null,
    checked: false,
    required: false,
    errorMessage: '',
    errors: []
  },
  on: {
    TOGGLE: {
      actions: ['toggleCheckbox', 'broadcastAnswer', 'broadcastToggle']
    },
    VALIDATE: [
      {
        target: 'valid',
        cond: 'checkRequirement',
        actions: 'clearErrors'
      },
      {
        target: 'invalid',
        actions: 'setErrors'
      }
    ]
  },
  states: {
    initial: {},
    valid: {
      tags: ['valid'],
      entry: 'clearErrorsToParent'
    },
    invalid: {
      entry: 'sendErrorsToParent'
    }
  }
}

export const checkboxOptions = {
  actions: {
    toggleCheckbox: assign(ctx => ({
      checked: !ctx.checked
    })),
    broadcastAnswer: sendParent(ctx => ({
      type: 'UPDATE_ANSWER',
      data: {
        value: ctx.checked,
        groupKey: ctx.groupKey,
        id: ctx.id
      }
    })),
    broadcastToggle: (ctx, event) => {
      const data = {
        ...event,
        question_key: ctx.id,
        checked: ctx.checked
      }
      broadcast('CHECKBOX_TOGGLE', data, ctx)
      Logger.log(logLevels.DEBUG, 'Checkbox toggled', data, ctx)
    },
    clearErrors: assign(() => ({
      errors: []
    })),
    clearErrorsToParent: sendParent(ctx => ({
      type: 'VALID',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        errors: []
      }
    })),
    setErrors: assign((ctx, event) => ({
      errors: [
        ctx.errorMessage
      ]
    })),
    sendErrorsToParent: sendParent(ctx => ({
      type: 'INVALID',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        errors: ctx.errors
      }
    }))
  },
  guards: {
    checkRequirement: ctx => !ctx.required || !!ctx.checked
  }
}

export const checkboxMachine = createMachine(checkboxDefinition, checkboxOptions)

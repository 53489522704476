import { createMachine, assign, sendParent } from 'xstate'
import { singleChoiceGroupDefinition, singleChoiceGroupOptions } from './SingleChoiceMachine'
import { choiceMachineContext } from '../utils/machine_utils/ChoiceMachines.utils'

/**
 * This machine is a slight offshoot of the SingleChoiceMachine, there are some overrides here
 * in regard to the way answers are stored (single values vs. array of values) as well as how
 * they are sent to the parent machine.
 **/
export const multipleChoiceDefinition = {
  ...singleChoiceGroupDefinition,
  id: 'multipleChoiceGroup',
  context: {
    ...choiceMachineContext,
    multiSelect: true,
    selected: []
  },
  on: {
    ...singleChoiceGroupDefinition.on,
    DESELECT: {
      actions: ['deselectValue', 'sendValueToParent']
    }
  }
}

export const multipleChoiceOptions = {
  ...singleChoiceGroupOptions,
  actions: {
    ...singleChoiceGroupOptions.actions,
    selectValue: assign({
      selected: (ctx, event) => {
        if (ctx.selected.includes(event.value)) {
          return [...ctx.selected]
        }
        return [...ctx.selected, event.value]
      }
    }),
    deselectValue: assign({
      selected: (ctx, event) => ctx.selected.filter(value => value !== event.value)
    }),
    // Sending the answers to the question machine as an encoded string of
    // comma delimited choices, this is what gets sent to the backend when traversing
    // Note that the delimiter is being specified even though its optional for clarity
    sendValueToParent: sendParent(ctx => ({
      type: 'UPDATE_ANSWER',
      data: {
        id: ctx.id,
        value: ctx.selected.map(value => encodeURIComponent(value)).join(','),
        groupKey: ctx.groupKey
      }
    }))
  },
  guards: {
    checkRequirement: ctx => !ctx.required || ctx.selected.length > 0
  }
}

export const multipleChoiceMachine = createMachine(multipleChoiceDefinition, multipleChoiceOptions)

import { createCss, keyframes } from '@stitches/react'

export const { styled } = createCss({
  theme: {
    colors: {
      white: '#fff',
      gray: 'darkgray',
      grayLight: '#efefef',
      grayMedium: '#c5c5c5',
      purple: '#370080',
      purpleLight: '#d2b0ff',
      purpleLighter: '#F6EDFE',
      purpleLightest: '#fbf7ff',
      brightGreen: '#77C345',
      saLightGrey: '#ccc',
      saTransparentGreen: 'rgba(67, 170, 89, 0.8)',
      saFontHighlight: 'rgba(67, 170, 89)',
      saTestimonialFont: '$white'
    },
    space: {
      1: '5px',
      2: '10px',
      3: '20px'
    },
    fontSizes: {
      1: '20px',
      2: '24px'
    },
    radii: {
      1: '5px'
    },
    sizes: {
      1: '100px'
    }
  },
  media: {
    bp0: '(max-width: 375px)',
    bp1: '(max-width: 768px)',
    bp2: '(max-width: 1100px)',
    // the entire purpose of this breakpoint to prevent the nav buttons from shifting up
    // when the on screen keyboard displays on android devices (esp. Pixel 5)
    bp3: '(max-height: 620px)'
  }
})

const fadeInKeyframe = keyframes({
  '0%': { opacity: '0' },
  '100%': { opacity: '1' }
})

export const fadeIn = { animation: `${fadeInKeyframe} 500ms` }

const spinKeyframe = keyframes({
  from: {
    transform: 'rotate(0deg)'
  },
  to: {
    transform: 'rotate(360deg)'
  }
})

export const spin = { animation: `${spinKeyframe} 2s linear infinite` }

export const createRequest = async (subscription, eventData, eventType, userId, sessionUUID) => {
  const { request, headers = {}, transform } = subscription

  // Default content-type is application/json; replace for x-www-form-urlencoded posts
  if (!headers['content-type']) {
    headers['content-type'] = 'application/json'
  }

  const reqBody = {
    timestamp: new Date(),
    user_id: userId,
    sessionUUID,
    eventType
  }

  let payload = { ...eventData, ...reqBody }
  delete payload.ref // remove xState interpreter

  if (transform) {
    const { default: jq } = await import(/* webpackChunkName: "jq" */ 'jq-in-the-browser')
    try {
      const query = jq(transform || '.')
      payload = query(payload)
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err)
      }
      throw new Error(`transform error for ${transform}`)
    }
  }

  if (headers['content-type']?.includes('application/x-www-form-urlencoded')) {
    const { default: qs } = await import(/* webpackChunkName: "qs" */ 'qs')
    payload = qs.stringify(payload)
  }

  const final = {
    ...request,
    headers,
    data: {
      ...payload
    }
  }

  if (final.headers['content-type']?.includes('application/x-www-form-urlencoded')) final.data = payload

  return final
}

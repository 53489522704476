import { styled } from '../utils/stitches.config'
import partnerStyles from '../styles/partners'

export const StyledFunnel = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '.heading--loading': {
    textAlign: 'center'
  },

  '&.funnel--initialLoading': {
    display: 'flex!important'
  },

  p: {
    fontSize: '31px',
    fontFamily: 'proxima-nova',
    margin: '0 auto 35px',
    textAlign: 'center',
    lineHeight: '36px'
  },

  // Temporary styles to be moved on SA side;
  '#sa-funnel_react-app &': {
    height: 'max-content'
  },
  '#sa-funnel_react-app &.funnel--initialLoading': {
    alignItems: 'center',
    paddingTop: '30vh',
    border: 'none',
    height: '100vh'
  },
  '#sa-funnel_react-app & .funnel--intermission-iframe': {
    height: '100vh'
  },
  '#sa-funnel_react-app & .funnel--question-container': {
    height: 'max(calc(100vh - 400px), 375px)',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0'
  },
  '#sa-funnel_react-app & .funnel--question-container---billboard': {
    height: 'max(-324px + 100vh, 505px)'
  },
  '#sa-funnel_react-app & .funnel--question-container---loading-panel': {
    height: 'max(-324px + 100vh, 765px)'
  },
  '#sa-funnel_react-app &.funnel--groupGroupUsername .funnel--question-container': {
    flexFlow: 'row wrap',
    placeContent: 'flex-start center',
    columnGap: '30px'
  },
  '#sa-funnel_react-app & .funnel--question-container p:nth-of-type(2)': {
    marginTop: '24px'
  },
  '#sa-funnel_react-app &.funnel--groupPhone': {
    height: 'calc(100vh + 110px)'
  },
  '#sa-funnel_react-app & .heading': {
    margin: '0px auto',
    fontWeight: '500',
    fontFamily: 'museo-sans',
    maxWidth: '586px'
  },
  '#sa-funnel_react-app & .heading--loading': {
    color: '$brightGreen',
    fontSize: '31px',
    fontWeight: '600',
    paddingTop: '24px'
  },
  '#sa-funnel_react-app & .subheading': {
    margin: '0px auto',
    fontWeight: '500',
    fontFamily: 'museo-sans',
    fontSize: '15px',
    maxWidth: '380px',
    lineHeight: '21px'
  },
  '#sa-funnel_react-app &.funnel--groupGroupUsername .heading': {
    flexGrow: '1',
    flexBasis: '100%',
    maxWidth: 'unset',
    margin: '0 auto 48px'
  },
  '#sa-funnel_react-app & .footer': {
    width: 'auto',
    margin: '16px 0',
    borderRadius: '10px',
    bottom: '10px',
    background: '#f4f4f6',
    zIndex: '3'
  },
  '#sa-funnel_react-app & .footer p': {
    display: 'block',
    margin: '0 auto',
    padding: '15px 0',
    lineHeight: '16px',
    maxWidth: '90%',
    fontSize: '13px',
    color: '#8C8C8C'
  },
  '#sa-funnel_react-app &.funnel--last-question-group .footer': {
    margin: '30px',
    marginTop: '0',
    width: 'auto'
  },

  '#sa-funnel_react-app & .disclaimer-footer': {
    width: 'auto',
    margin: '0 0 16px',
    bottom: '10px'
  },

  '#sa-funnel_react-app & .disclaimer-footer p': {
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#8C8C8C'
  },

  // Progress Bar (see also @bp1)
  '#sa-funnel_react-app &.funnel .progress': {
    marginTop: '16px',
    marginBottom: '48px',
    flexShrink: '0',
    transition: 'width .75s'
  },
  '#sa-funnel_react-app &.funnel .progress::-webkit-progress-value': {
    transition: 'width .75s',
    background: '#88CE32'
  },
  '#sa-funnel_react-app &.funnel .progress::-moz-progress-bar': {
    transition: 'width .75s',
    background: '#88CE32'
  },
  '#sa-funnel_react-app &.funnel .progress::-webkit-progress-bar': {
    transition: 'width .75s',
    borderColor: '#88CE32',
    background: '#f4f4f6'
  },
  '#sa-funnel_react-app &.funnel .spinner-spinning-bar': {
    borderTopColor: '#88CE32'
  },
  '#sa-funnel_react-app &.funnel .answer .input--radio': {
    border: '1px solid #333'
  },
  '#sa-funnel_react-app &.funnel .answer--selected .input--radio': {
    outline: 'none'
  },
  '#sa-funnel_react-app &.funnel .answer .input--multi_checkbox': {
    display: 'grid',
    placeContent: 'center',
    minHeight: '20px',
    minWidth: '20px',
    flexBasis: '1.2em',
    margin: '2px 13px',
    appearance: 'none',
    backgroundColor: '#fff',
    font: 'inherit',
    color: 'black',
    width: '20px',
    height: '20px',
    border: '1px solid #333',
    borderRadius: '2px'
  },
  '#sa-funnel_react-app &.funnel .answer .input--multi_checkbox::before': {
    content: '',
    width: '1em',
    height: '1em',
    display: 'none',
    clipPath: 'polygon(42% 95%, 4% 70%, 14% 55%, 39% 72%, 87% 12%, 99% 21%)',
    boxShadow: 'inset 1em 1em white'
  },
  '#sa-funnel_react-app &.funnel .answer .input--multi_checkbox:checked': {
    backgroundColor: '#20A7E2',
    borderColor: '#228ABA'
  },
  '#sa-funnel_react-app &.funnel .answer .input--multi_checkbox:checked::before': {
    display: 'block'
  },
  '#sa-funnel_react-app & .question': {
    flex: '1 0 25%',
    minHeight: 'auto',
    padding: '48px 50px 35px'
  },
  '& .question p': {
    fontWeight: '500',
    fontFamily: 'museo-sans'
  },
  '& .question .label': {
    maxWidth: '420px'
  },
  '#sa-funnel_react-app & .menu': {
    marginBottom: '0',
    flexShrink: '0'
  },
  '#sa-funnel_react-app & .menu .button': {
    padding: '11px 54px'
  },
  '#sa-funnel_react-app &.funnel--last-question-group .menu': {
    marginBottom: '40px'
  },
  '#sa-funnel_react-app &.funnel--last-question-group .menu .button--next': {
    padding: '11px 40px'
  },
  '#sa-funnel_react-app & .menu .button:after': {
    fontFamily: 'FontAwesome,sans-serif',
    content: '\\f054',
    fontSize: '13px',
    fontWeight: '400',
    top: '0'
  },
  '#sa-funnel_react-app & .menu .button--back': {
    padding: '11px 17px'
  },
  '#sa-funnel_react-app & .menu .button--back:after': {
    content: '\\f053',
    top: '2px'
  },
  '#sa-funnel_react-app & div.accordion--section span.accordion--title:after': {
    fontFamily: 'FontAwesome,sans-serif',
    fontSize: '16px',
    content: '\\f107',
    transform: 'none',
    marginLeft: '7px'
  },
  '#sa-funnel_react-app & div.accordion--section.active span.accordion--title:after': {
    fontFamily: 'FontAwesome,sans-serif',
    fontSize: '16px',
    content: '\\f106',
    transform: 'none'
  },
  '#sa-funnel_react-app &.funnel .answer': {
    flexShrink: '0',
    width: '94%',
    maxWidth: '380px'
  },
  '#sa-funnel_react-app & .question--location': {
    maxWidth: '380px'
  },
  '#sa-funnel_react-app & .question--type-multiple_choice .answer': {
    alignItems: 'center'
  },
  '#sa-funnel_react-app & .question--type-short_answer input': {
    maxWidth: '420px'
  },
  '#sa-funnel_react-app & .question--type-short_answer .errorMessages': {
    textAlign: 'left',
    maxWidth: '420px'
  },
  '#sa-funnel_react-app &.funnel--groupGroupUsername .question--firstName, #sa-funnel_react-app &.funnel--groupGroupUsername .question--lastName': {
    maxWidth: 'unset',
    width: '200px',
    minHeight: 'unset',
    height: '100%',
    padding: '0',
    flex: 'unset'
  },
  '#sa-funnel_react-app &.funnel--groupGroupUsername .question--firstName .input--text, #sa-funnel_react-app &.funnel--groupGroupUsername .question--lastName .input--text': {
    position: 'relative',
    width: '100%',
    top: 'unset',
    right: 'unset',
    left: 'unset'
  },
  '#sa-funnel_react-app &.funnel--groupGroupUsername .question .errorMessages': {
    position: 'relative',
    right: 'unset',
    top: 'unset',
    wordBreak: 'break-word'
  },
  '#sa-funnel_react-app &.funnel--groupGroupUsername .errorMessage': {
    position: 'relative',
    bottom: 'unset'
  },
  '#sa-funnel_react-app &.funnel--groupPhone .question--phone': {
    flex: 'unset'
  },
  '#sa-funnel_react-app &.funnel--groupPhone .question--termsOfService': {
    minHeight: '0',
    flexBasis: 'max-content'
  },
  '#sa-funnel_react-app &.funnel--groupPhone .question--termsOfService label': {
    width: '100%',
    maxWidth: '394px'
  },

  '@bp2': {
    '#sa-funnel_react-app & .question': {
      paddingBottom: '75px',
      flexShrink: '1'
    },
    '#sa-funnel_react-app & .funnel--question-container---billboard': {
      height: 'max(-324px + 100vh, 528px)'
    },
    '#sa-funnel_react-app & .disclaimer-footer': {
      marginTop: '16px',
      marginLeft: '16px',
      marginRight: '16px'
    },
    '#sa-funnel_react-app &.funnel--noBack .menu .button--next': {
      marginBottom: '0px'
    },
    '#sa-funnel_react-app &.funnel--groupGroupUsername .funnel--question-container': {
      columnGap: '125px',
      rowGap: '20px'
    },
    // workaround for gap properties not working flex containers in older version of iOS
    '#sa-funnel_react-app &.funnel--groupGroupUsername .funnel--question-container > *': {
      '@supports (-webkit-touch-callout: none) and (not (translate: none))': {
        '&:not(:last-child)': {
          marginRight: '125px',
          marginBottom: '20px'
        }
      }
    },
    '#sa-funnel_react-app &.funnel--groupGroupUsername .heading': {
      marginBottom: '28px'
    },
    '#sa-funnel_react-app .funnel .question.question--termsOfService label': {
      width: '100%',
      maxWidth: '400px'
    },
    '#sa-funnel_react-app & .footer': {
      width: '100%'
    }
  },
  '@bp1': {
    '#sa-funnel_react-app & ': {
      height: '100vh',
      width: '100vw',
      paddingLeft: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingTop: '24px'
    },
    '#sa-funnel_react-app &.funnel .progress': {
      marginBottom: '16px'
    },
    '#sa-funnel_react-app & .funnel--question-container': {
      height: 'max(calc(100vh - 400px), 390px)',
      flexShrink: '0'
    },
    '#sa-funnel_react-app & .funnel--question-container---billboard, #sa-funnel_react-app & .funnel--question-container---loading-panel': {
      height: 'max(calc(100vh - 400px), 460px)'
    },
    '#sa-funnel_react-app & .disclaimer-footer p': {
      lineHeight: '13px'
    },
    '#sa-funnel_react-app & .heading': {
      marginTop: '0',
      marginBottom: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      fontSize: '22px',
      lineHeight: '30px',
      maxHeight: 'fit-content'
    },
    '#sa-funnel_react-app & .subheading': {
      marginTop: '0',
      marginBottom: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      maxHeight: 'fit-content'
    },
    '#sa-funnel_react-app & .funnel--question-container p:nth-of-type(2)': {
      marginTop: '0'
    },
    '#sa-funnel_react-app &.funnel--groupGroupUsername .heading': {
      marginBottom: '0'
    },
    // workaround for gap properties not working flex containers in older version of iOS
    '#sa-funnel_react-app &.funnel--groupGroupUsername .funnel--question-container > *': {
      '@supports (-webkit-touch-callout: none) and (not (translate: none))': {
        '&:not(:last-child)': {
          marginRight: '0'
        }
      }
    },
    '#sa-funnel_react-app & .question': {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '0',
      paddingTop: '0',
      flexGrow: '1',
      flexShrink: '0',
      maxHeight: '50vh'
    },
    '#sa-funnel_react-app &.funnel .question--type-short_answer:nth-of-type(1)': {
      minHeight: 'max-content'
    },
    '#sa-funnel_react-app &.funnel--groupGroupUsername .question--firstName, #sa-funnel_react-app &.funnel--groupGroupUsername .question--lastName': {
      width: '100%',
      paddingLeft: '16px',
      paddingRight: '16px',
      height: 'auto'
    },
    '#sa-funnel_react-app &.funnel--groupPhone .question--phone': {
      flexBasis: '0'
    },
    '#sa-funnel_react-app &.funnel--groupPhone .question--termsOfService': {
      maxHeight: '26%'
    },
    '#sa-funnel_react-app &.funnel--groupPhone .question--termsOfService label': {
      width: '100%'
    },
    '#sa-funnel_react-app & .menu': {
      height: 'max-content'
    },
    '#sa-funnel_react-app & .accordion--section': {
      width: '100%'
    },
    '#sa-funnel_react-app &.funnel--last-question-group .footer': {
      marginLeft: '0',
      marginRight: '0',
      marginTop: '16px',
      bottom: '0',
      borderRadius: 'unset'
    },
    '#sa-funnel_react-app & .footer': {
      marginLeft: '0',
      marginRight: '0',
      borderRadius: 'unset'
    },
    '#sa-funnel_react-app & iframe': {
      height: '100vh'
    }
  },
  '@bp0': {
    '#sa-funnel_react-app & ': {
      height: 'max-content'
    },
    '#sa-funnel_react-app &.funnel--last-question-group .funnel--question-container': {
      height: 'max(calc(-400px + 100vh), 300px)'
    },
    '#sa-funnel_react-app & .question': {
      flexBasis: '30%'
    },
    '#sa-funnel_react-app &.funnel--groupPhone .question--termsOfService': {
      maxHeight: '24%'
    },
    '#sa-funnel_react-app & .menu': {
      flexShrink: '0'
    }
  },
  // the entire purpose of this breakpoint to prevent the nav buttons from shifting up
  // when the on-screen keyboard displays on android devices (esp. Pixel 5)
  '@bp3': {
    '#sa-funnel_react-app & .question': {
      flexBasis: '60%'
    },
    '#sa-funnel_react-app &.funnel--groupPhone .question--termsOfService': {
      flexBasis: '60%',
      maxHeight: '35%'
    },
    '#sa-funnel_react-app &.funnel--last-question-group .footer': {
      position: 'relative'
    }
  },

  '@media (hover: none)': {
    '#sa-funnel_react-app & .menu .button--next:hover': {
      background: '#88CE32'
    }
  },
  // END Temporary styles to be moved on SA side

  // Partner styles under #smartadvisor-captivate-funnel
  ...partnerStyles

})

/* globals logLevels, subscriptionsStoreBaseName, subscriptionsSendInterval, subscriptionsSendsPerCycle, subscriptionsResetOnStart */
import Logger from '../logger/logger'
import eventStore from './eventStore'
import transmitter from './transmitter'
import { createRequest } from './createRequest'
import { prepareResponses } from '../machine_utils/QuestionMachine.utils'
import { runCustomAction } from '../utils'

let subscriptionsStore, subscriptionsTransmitter

// Create event payloads to send to subscriptions defined in the funnel
// config file and attempt to send them
export default (type, data, ctx) => {
  if (!subscriptionsStore) {
    subscriptionsStore = eventStore(subscriptionsStoreBaseName, subscriptionsResetOnStart)
  }

  if (!subscriptionsTransmitter) {
    subscriptionsTransmitter = transmitter(subscriptionsStore, subscriptionsSendInterval, subscriptionsSendsPerCycle)
    Logger.log(logLevels.INFO, 'TRANSMITTER INITIALIZATION',
      {
        message: 'Transmitter started',
        store_name: subscriptionsStoreBaseName,
        transmitterId: subscriptionsTransmitter
      }, ctx)
  }

  if (process.env.NODE_ENV === 'test') return
  const {
    userId,
    sessionUUID
  } = ctx
  const eventSubscriptions = ctx.eventSubscriptions

  // handles all external event subscription requests
  if (eventSubscriptions && Object.prototype.hasOwnProperty.call(eventSubscriptions, type)) {
    // Get the list of subscribers for this event type
    const subscriptions = eventSubscriptions[type]

    for (const subscription of subscriptions) {
      if (!subscription.cond || subscription.cond.question_key === data.question_key) {
        let payload = { ...data }
        const transform = subscription.transform
        if (transform?.includes('.responses')) {
          const mixpanelId = (new Date().toISOString()) + '_' + (userId || sessionUUID)
          window.com_smartasset_smartadvisor_funnel.mixpanel_id = mixpanelId
          payload = {
            ...payload,
            mixpanelId,
            responses: [
              ...ctx.responses,
              ...prepareResponses(ctx.question_groups)
            ]
          }
        }
        if (transform?.includes('.utm')) {
          payload = { ...payload, utm: ctx.utm }
        }

        createRequest(subscription, payload, type, userId, sessionUUID)
          .then((request) => {
            subscriptionsStore.push(request)
          })
      }
    }
  }

  runCustomAction(ctx, type, data)
}

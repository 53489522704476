import { singleChoiceGroupDefinition, singleChoiceGroupMachine } from '../../machines/SingleChoiceMachine'
import { formInputDefinition, formInputMachine } from '../../machines/FormTextFieldMachine'
import { checkboxDefinition, checkboxMachine } from '../../machines/CheckboxMachine'
import { multipleChoiceDefinition, multipleChoiceMachine } from '../../machines/MultipleChoiceMachine'

import { spawn } from 'xstate'
import { createValidationSchema } from '../validation_utils/yup.utils'

export const getCurrentQuestionGroupIndex = (questionGroups, questionGroup) =>
  questionGroups.findIndex((g) => g.group_key === questionGroup.group_key)

export const createSingleChoiceGroupMachine = (question, groupKey, autoFocusEnabled, isSingleClick) => {
  let { type, params } = question.validations ? question.validations[0] : {}

  // FUN-356 Backwards compatibility for the single_choice_required flag
  if (question.validations === undefined && question.single_choice_required) {
    type = 'required'
    params = ['This field is required']
  }

  const selected = isSingleClick
    ? null
    : question.pastAnswer || question.answer_choices.find(answer => answer.default)?.value

  return singleChoiceGroupMachine.withContext({
    ...singleChoiceGroupDefinition.context,
    id: question.question_key,
    groupKey,
    question: question.question_key,
    question_text: question.question_text,
    selected,
    required: type === 'required',
    errorMessage: params ? params[0] : '',
    answer_choices: question.answer_choices,
    autofocus_enabled: autoFocusEnabled,
    errors: [],
    isSingleClick
  })
}

export const createMultipleChoiceMachine = (question, groupKey, autoFocusEnabled) => {
  const { type, params } = question.validations ? question.validations[0] : {}
  // Past answers are stored as comma delimited encoded strings since they're being sent in this manner to the backend
  // see MultipleChoiceMachine.js for more details, this logic always ensures there is an array of past answers
  // even if it is an empty one
  const pastAnswer = question.pastAnswer?.split(',')
    .map(value => decodeURIComponent(value)).filter(Boolean) || []

  const defaultSelected = question.answer_choices.filter(answer => {
    return answer?.default
  }).map(answer => {
    return answer.value
  }).filter(Boolean)

  return multipleChoiceMachine.withContext({
    ...multipleChoiceDefinition.context,
    id: question.question_key,
    groupKey,
    question: question.question_key,
    question_text: question.question_text,
    selected: pastAnswer.length ? pastAnswer : defaultSelected,
    required: type === 'required',
    errorMessage: params ? params[0] : '',
    answer_choices: question.answer_choices,
    autofocus_enabled: autoFocusEnabled,
    errors: []
  })
}

export const createFormTextFieldMachine = (question, groupKey) => {
  return formInputMachine.withContext({
    ...formInputDefinition.context,
    id: question.question_key,
    groupKey,
    name: question.question_key,
    validation_schema: question.validations && createValidationSchema(question.validations, question.question_key),
    autofocus_enabled: false,
    autofill: question.autofill,
    value: question.value || '',
    errors: []
  })
}

export const createCheckboxMachine = (question, groupKey, autoFocusEnabled) => {
  const { type, params } = question.validations ? question.validations[0] : {}

  return checkboxMachine.withContext({
    ...checkboxDefinition.context,
    id: question.question_key,
    groupKey,
    question_text: question.question_text,
    required: type === 'required',
    checked: question.default_checked,
    autofocus_enabled: autoFocusEnabled,
    errorMessage: params ? params[0] : '',
    errors: []
  })
}

export const updateQuestionInGroup = (questionGroup, event, questionModifierFn) => ({
  ...questionGroup,
  questions: questionGroup.questions?.map((q, i) =>
    (event.data.groupKey === questionGroup.group_key && event.data.id === q.question_key)
      ? questionModifierFn(event, q, i)
      : q)
})

export const updateQuestionGroups = (questionGroups, event, questionModifierFn) =>
  questionGroups.map((g) => updateQuestionInGroup(g, event, questionModifierFn))

export const updateMachineContext = (ctx, event, questionModifierFn, autoFocusEnabled) => {
  const questionGroups = updateQuestionGroups(ctx.question_groups, event, questionModifierFn, autoFocusEnabled)
  let questionGroup = ctx.question_group
  if (event.data.groupKey === ctx.question_group.group_key) {
    questionGroup = questionGroups.find(g => event.data.groupKey === g.group_key)
  }

  return {
    question_groups: questionGroups,
    question_group: questionGroup,
    autofocus_enabled: ctx.autofocus_enabled
  }
}

export const updateQuestionGroupMeta = (ctx, updaterFn) => {
  const questionGroups = ctx.question_groups.map(g => {
    if (g.group_key === ctx.question_group.group_key) {
      return updaterFn(g)
    } else {
      return g
    }
  })

  const questionGroup = questionGroups.find(g => g.group_key === ctx.question_group.group_key)

  return {
    question_groups: questionGroups,
    question_group: questionGroup
  }
}

export const isConditionMet = (questionGroups, condition) => {
  const { group_key: groupKey, question_key: questionKey, value } = condition
  if (!groupKey || !questionKey || !value) {
    return false
  }
  const questionGroup = questionGroups.find(g => g.group_key === groupKey)
  if (!questionGroup) {
    return false
  }
  const question = questionGroup.questions?.find(q => q.question_key === questionKey)
  if (!question) {
    return false
  }
  if (value === question.value) {
    return true
  }
  // If the question isn't answered, check for past and default answers (for question machine prep)
  if (!question.value) {
    if (question.pastAnswer && value === question.pastAnswer) {
      return true
    } else if (!question.pastAnswer) {
      return question.answer_choices?.find(choice => !!choice.default)?.value
    }
  }
}

export const prepareResponses = (questionGroups) =>
  questionGroups.reduce((accumulator, group) =>
    accumulator.concat(group.questions?.filter(question =>
      !!question.value).map(question => ({
      question_key: question.question_key,
      questionValue: question.questionValue,
      value: question.value
    }))), []).filter(Boolean)

export const prepareActors = (questionGroup, autoFocusEnabled) => {
  let isFirstAndFocused = autoFocusEnabled
  const { group_key: groupKey } = questionGroup
  questionGroup.questions = questionGroup?.questions?.map(q => {
    let ref = null
    q.value = q.value || q.pastAnswer
    if (q.answer_type === 'single_choice') {
      const isSingleClick = questionGroup.meta_content?.is_single_click
      ref = spawn(createSingleChoiceGroupMachine(q, groupKey, isFirstAndFocused, isSingleClick))
      q.value = isSingleClick ? null : q.value || q.answer_choices.find(c => c.default)?.value || ''
    } else if (q.answer_type === 'short_answer') {
      ref = spawn(createFormTextFieldMachine(q, groupKey, isFirstAndFocused))
    } else if (q.answer_type === 'checkbox') {
      ref = spawn(createCheckboxMachine(q, groupKey, isFirstAndFocused))
    } else if (q.answer_type === 'multiple_choice') {
      ref = spawn(createMultipleChoiceMachine(q, groupKey, isFirstAndFocused))
    }
    isFirstAndFocused = false

    return {
      ...q,
      ref
    }
  })

  return questionGroup
}

export const evaluateBranching = (questionGroups, questionGroup) => {
  const branchingConfig = questionGroup.branching || []
  const groupData = questionGroup

  // start looking for the condition to be met
  groupData.winningBranch = branchingConfig.find((config) => {
    if (config.criteria) {
      /**
       * for each question in the question groups
       * we want to get the question group in the condition
       * and check for the question key in the condition
       * such that the value matches that of the condition
       * if successful return the target group key
       * otherwise return the next question group key index
       */
      return config.criteria.find(criterion => isConditionMet(questionGroups, criterion))
    } else {
      return config
    }
  })
  return groupData
}

export const getNextQuestionGroup = (questionGroups, questionGroup, winningBranch) => {
  const targetGroupKey = winningBranch?.target_group_key

  let nextQuestion
  // base case without any branching configuration on config or failed to meet next condition
  if (!winningBranch || !targetGroupKey) {
    nextQuestion = questionGroups[getCurrentQuestionGroupIndex(questionGroups, questionGroup) + 1]
  } else {
    nextQuestion = questionGroups.find(g => g.group_key === targetGroupKey)
  }
  nextQuestion.prevQuestionGroupKey = questionGroup.group_key
  return nextQuestion
}

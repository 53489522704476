import { createMachine, assign, sendParent } from 'xstate'
import { choiceMachineContext } from '../utils/machine_utils/ChoiceMachines.utils'

const setErrorMessage = (errorMessage) => {
  const errors = []
  if (errorMessage) {
    errors.push(errorMessage)
  }
  return {
    errors
  }
}

export const singleChoiceGroupDefinition = {
  id: 'radioGroup',
  initial: 'initial',
  context: {
    ...choiceMachineContext,
    isSingleClick: false
  },
  on: {
    SELECT: {
      actions: ['selectValue', 'sendValueToParent']
    },
    VALIDATE: [
      {
        target: 'valid',
        cond: 'checkRequirement',
        actions: 'clearErrors'
      },
      {
        target: 'invalid',
        actions: 'setErrors'
      }
    ]
  },
  states: {
    initial: {},
    valid: {
      tags: ['valid'],
      entry: 'broadcastValid'
    },
    invalid: {
      entry: 'broadcastInvalid'
    }
  }
}

export const singleChoiceGroupOptions = {
  actions: {
    selectValue: assign({
      selected: (ctx, event) => ctx.isSingleClick ? null : event.value,
      value: (ctx, event) => event.value
    }),
    setErrors: assign(ctx => setErrorMessage(ctx.errorMessage)),
    clearErrors: assign(setErrorMessage()),
    sendValueToParent: sendParent(ctx => ({
      type: 'UPDATE_ANSWER',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        value: ctx.value
      }
    })),
    broadcastValid: sendParent(ctx => ({
      type: 'VALID',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        errors: ctx.errors
      }
    })),
    broadcastInvalid: sendParent(ctx => ({
      type: 'INVALID',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        errors: ctx.errors
      }
    }))
  },
  guards: {
    checkRequirement: ctx => !ctx.required || !!ctx.value
  }
}

export const singleChoiceGroupMachine = createMachine(singleChoiceGroupDefinition, singleChoiceGroupOptions)

export const choiceMachineContext = {
  id: null,
  groupKey: null,
  selected: '',
  required: false,
  errorMessage: '',
  answer_choices: [],
  autofocus_enabled: false,
  errors: []
}

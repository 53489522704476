import React from 'react'
import { StyledFunnel } from './StyledFunnel'
import { FinalIntermission } from './FinalIntermission'

const LoadingFunnel = () => <StyledFunnel className='funnel funnel--initialLoading' data-testid='loading_funnel'>
  <FinalIntermission state='initialLoading'/>
  <h1 className='heading--loading'>Generating your quiz…</h1>
</StyledFunnel>

export default LoadingFunnel

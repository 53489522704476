/* globals validationUrl */
import * as Yup from 'yup'

// Zip
export function isValidZip () {
  const regex = /\d{5}/
  return this.test(
    'zipcodeService',
    'Please use a valid zipcode',
    async (zip) => {
      // zip code validation endpoint will return an empty response if zip is invalid
      if (regex.test(zip)) {
        // zip code validation endpoint will return an empty response if zip is invalid
        try {
          const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios')
          const res = await axios({
            url: `${validationUrl}/data/locations?id=${zip}`,
            method: 'GET',
            responseType: 'text'
          })
          // Yup custom validations expect a boolean return; given current endpoint logic,
          // a string response indicates valid location data; an empty string implies that
          // location data was invalid; in the event that there is a single digit request
          // there is a special response string that is sent though technically this should
          // never be experienced because of the zip.length >= 5 restriction earlier
          return res.status === 200 && // good response
              res.data.length && // with length
              res.data !== '-1:Query too short' // not the low-digit count response
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.log(`Location validation service error: ${error.response}`)
          }
          return false
        }
      } else {
        return false
      }
    }
  )
}

// Email
export function isValidEmail () {
  const regex = /^\S+@\S+\.\S+$/
  return this.test(
    'emailService',
    'Please enter a valid email address',
    async (email) => {
      if (regex.test(email)) {
        try {
          // The email validation request must be application/x-www-form-urlencoded and
          // use a stringified email address because of current endpoint limitations; see
          // https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
          const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios')
          const { default: qs } = await import(/* webpackChunkName: "qs" */ 'qs')
          const res = await axios({
            url: `${validationUrl}/validatefalc/validateemail`,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            responseType: 'text',
            data: qs.stringify({
              email: email.toLowerCase()
            })
          })

          // current email validation endpoint returns true for valid emails
          return res.data === true
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.log(`Email validation service error: ${error.response}`)
          }
          return false
        }
      } else {
        return false
      }
    }
  )
}

export function isValidPhone () {
  return this.test(
    'phone',
    'Please enter a valid phone',
    async (phone) => {
      if (phone.length >= 9) {
        try {
          const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios')
          const res = await axios({
            url: `${validationUrl}/validatefalc/validate?fname=&lname=&phone=${phone}`, // TODO get the phone out of the logged URL
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            responseType: 'text'
          })

          return !!res.data.validPhone
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.log(`Contact info validation service error: ${error.response}`)
          }
          return false
        }
      } else {
        return false
      }
    }
  )
}

// test for repeating characters, should fail if there are more than 2 repeating characters
// in the string
export function checkForRepeats (questionKey) {
  let field = 'first'
  if (questionKey.includes('last')) {
    field = 'last'
  }
  const regex = /([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])\1{2}/
  return this.test(
    'repeats',
    `Please enter a valid ${field} name`,
    text => {
      if (regex.test(text)) {
        return false
      } else {
        return true
      }
    }
  )
}

export function createValidationSchema (validations, questionKey, baseValidator = 'string') {
  const schema = Yup.object().shape({})
  const base = Yup[baseValidator]()

  validations.forEach(validation => {
    const {
      params,
      type
    } = validation

    if (base[type]) {
      // adding multiple validations if the schema for a given field already has value
      if (!schema[questionKey]) {
        schema[questionKey] = base[type](...params)
      } else {
        schema[questionKey] = schema[questionKey].concat(base[type](...params))
      }
    }
  })

  // Add custom validators
  if (questionKey === 'location') {
    Yup.addMethod(Yup.string, 'isValidZip', isValidZip)
    schema[questionKey] = schema[questionKey].concat(base.isValidZip())
  } else if (questionKey === 'email') {
    Yup.addMethod(Yup.string, 'isValidEmail', isValidEmail)
    schema[questionKey] = schema[questionKey].concat(base.isValidEmail())
  } else if (questionKey === 'phone') {
    Yup.addMethod(Yup.string, 'isValidPhone', isValidPhone)
    schema[questionKey] = schema[questionKey].concat(base.isValidPhone())
  } else if (questionKey.includes('name')) {
    Yup.addMethod(Yup.string, 'checkForRepeats', checkForRepeats)
    schema[questionKey] = schema[questionKey].concat(base.checkForRepeats(questionKey))
  }

  return schema
}

/* globals enablePropTypes */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FinalIntermission } from './FinalIntermission'

const PostFunnelFrame = ({ iFrameUrl }) => {
  const [loading, setLoading] = useState(true)

  const matchPanelUrl = iFrameUrl + '&sessionUUID=' + window.com_smartasset_smartadvisor_funnel?.sessionUUID
  const hideLoader = () => {
    setLoading(false)
  }
  return <>
    {loading ? (<FinalIntermission/>) : null}
    <iframe
      src={matchPanelUrl}
      frameBorder='0'
      onLoad={hideLoader}
      width="100%"
      height="100%"/>
  </>
}

if (enablePropTypes) {
  PostFunnelFrame.propTypes = {
    iFrameUrl: PropTypes.string.isRequired
  }
}

export default PostFunnelFrame

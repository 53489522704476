/* globals enableXStateInspector, enablePropTypes */
import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { useInterpret, useSelector } from '@xstate/react'
import { getQuestionMachineServiceReference } from '../utils/machine_utils/FunnelMachine.selectors'
import { funnelMachine } from '../machines/FunnelMachine'
import { transformAttributes } from '../utils/utils'
import { questionMachine } from '../machines/QuestionMachine'

export const StateMachineContext = createContext({})

const StateMachineProvider = ({ attributes, children }) => {
  const {
    configUrl,
    options,
    userId,
    sessionUUID,
    utmString,
    retargetSession
  } = transformAttributes(attributes)

  const funnelMachineService = useInterpret(funnelMachine, {
    context: {
      configUrl,
      userId,
      sessionUUID,
      startEnabled: options.startEnabled,
      utmString,
      retargetSession
    },
    devTools: enableXStateInspector,
    services: {
      questionMachine
    }
  })

  const questionMachineService = useSelector(funnelMachineService, getQuestionMachineServiceReference)

  return (
    <StateMachineContext.Provider value={{ funnelMachineService, questionMachineService, options }}>
      {children}
    </StateMachineContext.Provider>
  )
}

if (enablePropTypes) {
  StateMachineProvider.propTypes = PropTypes.shape({
    'session-uuid': PropTypes.string,
    user_identifier: PropTypes.string,
    config: PropTypes.string.isRequired,
    options: PropTypes.string,
    utms: PropTypes.string,
    'disable-session-cookie': PropTypes.string
  }).isRequired
}

export { StateMachineProvider }

/* globals enablePropTypes */
import React from 'react'
import PropTypes from 'prop-types'
import { styled, spin } from '../utils/stitches.config'

// markup and styles taken from SAapp loading spinner animation
// with some modifications for positioning.
const StyledFinalIntermission = styled('div', {
  position: 'absolute',
  zIndex: '2',
  opacity: '0.6',
  backgroundColor: 'white',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& .spinner-round-box': {
    width: '60px',
    height: '60px',
    zIndex: '3',

    '& .spinner-spinning-bar': {
      display: 'block',
      position: 'relative',
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      border: '10px solid #CCC',
      borderTopColor: '#8C8C8C',
      ...spin,

      '& .spinner-edge': {
        display: 'block',
        position: 'absolute',
        borderRadius: '50%',
        width: '9px',
        height: '9px',

        '&.edge-left': {
          top: '1px',
          left: '1px'
        },
        '&.edge-right': {
          top: '0px',
          right: '1px'
        }
      }
    }
  },

  variants: {
    state: {
      initialLoading: {
        height: '100px',
        position: 'relative'
      }
    }
  }
})

// See c_lejeune and Scott Buchanan's answers here:
// https://stackoverflow.com/questions/38704467/how-to-label-a-loading-animation-for-wai-aria

export const FinalIntermission = (variants) =>
  <StyledFinalIntermission
    as='div'
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    aria-busy="true"
    aria-live="polite"
    aria-valuetext="Loading..."
    aria-label="Loading results"
    {...variants}
  >
    <div className="spinner-round-box">
      <span className="spinner-spinning-bar">
        <span className="spinner-edge spinner-edge-left"/>
        <span className="spinner-edge spinner-edge-right"/>
      </span>
    </div>
  </StyledFinalIntermission>

if (enablePropTypes) {
  FinalIntermission.propTypes = {
    variants: PropTypes.object
  }
}

export default async function autofill (config, fieldValue) {
  if (fieldValue) return fieldValue

  if (!config) return ''

  const { url, transform } = config

  if (!url || !transform) return ''

  const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios')
  const res = await axios({
    url,
    method: 'GET'
  })

  if (!res.data) return ''

  const { default: jq } = await import(/* webpackChunkName: "jq" */ 'jq-in-the-browser')
  const query = jq(transform)
  return query(res.data)
}

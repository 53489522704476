export default {
  '#smartadvisor-captivate-funnel & ': {
    display: 'block',
    flex: 'unset',
    minHeight: '632px',
    minWidth: '300px',
    maxWidth: '760px',
    margin: 'auto',
    padding: '20px',
    fontFamily: '"Open Sans",sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    color: '#333333',
    backgroundColor: '#fff',
    position: 'relative',
    width: '100%',
    height: 'auto',
    border: 'none',
    overflow: 'auto',
    '-webkit-font-smoothing': 'anti-aliased'
  },
  '#smartadvisor-captivate-funnel & .funnel': {
    flex: 'unset',
    position: 'absolute',
    display: 'block'
  },
  '#smartadvisor-captivate-funnel & h3': {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '24px'
  },
  '#smartadvisor-captivate-funnel & .heading': {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: 'normal',
    margin: '32px 0 24px 0'
  },
  '#smartadvisor-captivate-funnel & .answer': {
    display: 'flex',
    alignItems: 'center',
    flex: 'unset',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: '400',
    height: '50px',
    width: '100%',
    padding: '0',
    border: '1px solid #8c8c8c',
    borderRadius: '8px',
    color: '#333333',
    backgroundColor: '#ffffff',
    marginBottom: '12px',
    position: 'relative'
  },
  '#smartadvisor-captivate-funnel & .question': {
    flex: 'unset'
  },
  '#smartadvisor-captivate-funnel & .input--radio': {
    height: '16px'
  },
  '#smartadvisor-captivate-funnel & .menu': {
    position: 'relative',
    minWidth: '300px',
    maxWidth: '760px',
    margin: 'auto',
    width: '100%',
    height: '40px',
    marginBottom: '16px'
  },
  '#smartadvisor-captivate-funnel & .menu .button': {
    color: 'white',
    backgroundColor: '#8C8C8C',
    fontFamily: 'OpenSans,FontAwesome,sans-serif',
    fontSize: '18px',
    textAlign: 'center',
    width: '168px',
    height: '40px',
    margin: '0px',
    cursor: 'pointer',
    borderStyle: 'none',
    lineHeight: '20px'
  },
  '#smartadvisor-captivate-funnel & .menu .button--next': {
    position: 'absolute',
    right: '0'
  },
  '#smartadvisor-captivate-funnel & .menu .button--back:before': {
    content: 'url("https://captivate.smartasset.com/partner-arrow.svg")',
    position: 'absolute',
    left: '0'
  },
  '#smartadvisor-captivate-funnel & .menu .button--back': {
    position: 'absolute',
    left: '0',
    color: 'transparent',
    backgroundColor: 'transparent',
    border: '0',
    width: '120px'
  },
  '#smartadvisor-captivate-funnel & .menu .button--back:after': {
    content: 'Back',
    color: '#8c8c8c',
    backgroundColor: 'white',
    marginLeft: '-50px'
  },
  '#smartadvisor-captivate-funnel & .accordion--section': {
    minWidth: '300px',
    maxWidth: '760px',
    fontSize: '10px',
    fontWeight: '400',
    color: '#8C8C8C',
    backgroundColor: '#F4F4F6',
    padding: '16px 32px'
  },
  '#smartadvisor-captivate-funnel & .accordion--paragraph': {
    fontWeight: '400',
    paddingBottom: '15px'
  },
  '#smartadvisor-captivate-funnel & iframe': {
    minHeight: '632px'
  },
  '#smartadvisor-captivate-funnel & input.input--radio': {
    cursor: 'pointer',
    display: 'flex',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '40px',
    margin: '0',
    opacity: '0'
  },
  '#smartadvisor-captivate-funnel & input[type="radio" i]:focus-visible': {
    outline: 'none'
  },
  '#smartadvisor-captivate-funnel & .answer--selected': {
    backgroundColor: '#E8E8E8'
  },
  '#smartadvisor-captivate-funnel & .progress': {
    appearance: 'none',
    border: '1px solid #8C8C8C',
    borderRadius: '30px'
  },
  '#smartadvisor-captivate-funnel & .progress.progress-disabled': {
    backgroundColor: '#F4F4F6'
  },
  '#smartadvisor-captivate-funnel & progress[value]::-webkit-progress-value': {
    backgroundColor: '#219FDB',
    borderRadius: '30px'
  },
  '#smartadvisor-captivate-funnel & progress[value]::-webkit-progress-bar': {
    backgroundColor: 'transparent'
  },
  '#smartadvisor-captivate-funnel & .question--type-single_choice label': {
    marginLeft: '17px'
  },
  '#smartadvisor-captivate-funnel & .input--text': {
    borderRadius: '8px',
    border: '1px solid #8c8c8c'
  },
  '#smartadvisor-captivate-funnel & .question--invalidResponse .input--text': {
    borderColor: '#F2364D'
  },
  '#smartadvisor-captivate-funnel & .errorMessages': {
    color: '#F2364D'
  },
  '#smartadvisor-captivate-funnel & .accordion--header': {
    cursor: 'pointer',
    position: 'relative'
  },
  '#smartadvisor-captivate-funnel & .accordion--section .accordion--indicator': {
    color: '#333',
    position: 'absolute',
    right: '32px',
    bottom: '10px',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  '#smartadvisor-captivate-funnel & .accordion--section .accordion--paragraph.truncated': {
    maxWidth: '91%'
  },
  '#smartadvisor-captivate-funnel & .accordion--section.active .accordion--paragraph': {
    paddingBottom: '0px'
  },
  '#smartadvisor-captivate-funnel & .accordion--section .accordion--indicator:after': {
    content: 'url("https://captivate.smartasset.com/caret.svg")',
    display: 'inline-block',
    transform: 'rotate(180deg)',
    marginLeft: '3px',
    verticalAlign: 'middle'
  },
  '#smartadvisor-captivate-funnel & .accordion--section.active .accordion--indicator:after': {
    transform: 'rotate(0deg)'
  },
  '#smartadvisor-captivate-funnel & .accordion--section .accordion--header': {
    marginBottom: '15px'
  },
  '#smartadvisor-captivate-funnel & .extraMessage': {
    position: 'absolute',
    right: '50px',
    top: '-25px'
  },
  '#smartadvisor-captivate-funnel & .metacontent': {
    position: 'relative'
  },
  '#smartadvisor-captivate-funnel & .metacontent .powered-by-sa': {
    content: 'url("https://captivate.smartasset.com/powered_by_sa.svg")',
    position: 'absolute',
    right: '0',
    marginTop: '3px'
  },
  '#smartadvisor-captivate-funnel & .metacontent .footer': {
    background: '#F4F4F6',
    padding: '15px 10px',
    fontSize: '10px',
    color: '#8C8C8C'
  },
  '#smartadvisor-captivate-funnel & .question:last-of-type': {
    minHeight: '250px'
  },
  '#smartadvisor-captivate-funnel & p': {
    fontFamily: '"Open Sans", sans-serif'
  },
  '#smartadvisor-captivate-funnel & .subheading': {
    fontSize: '14px',
    lineHeight: 'initial'
  },
  '#smartadvisor-captivate-funnel & .footer p': {
    fontSize: '10px',
    lineHeight: 'initial',
    textAlign: 'left'
  },
  // mobile-specific styling for partner funnels
  '@bp0': {
    '#smartadvisor-captivate-funnel & .metacontent .accordion--section': {
      padding: '16px'
    },
    '#smartadvisor-captivate-funnel & .metacontent .accordion--section .accordion--indicator': {
      right: '15px',
      bottom: '15px'
    }
  }
}

// initialized[] contains names of event stores that have been used; used is defined as a
//    store having had a call to any of the public push, pull, length or clear functions
//    and thus contains at least the empty array []
const initialized = []
const stores = {}

// eventStore is a function operating as a class with a constructor taking the store name;
//    note that functions beginning with the _ underscore character should not be used
//    outside this file (and possibly, carefully in tests)
export const eventStoreClass = (storeName) => {
  if (!stores[storeName]) {
    stores[storeName] = {
      name: storeName,
      _pushed: 0,
      _transmitted: 0,

      // Private method; not to be used outside of this class
      _create () {
        try { // Mobile Safari in private mode will throw an error
          window.localStorage.setItem(storeName, '[]')
          initialized[storeName] = true
        } catch {}
        return []
      },

      // Private method; not to be used outside of this class
      _delete () {
        window.localStorage.removeItem(storeName)
      },

      // Private method; not to be used outside of this class
      // Note that _get does not alter the existing array in localStorage, if present,
      //    whereas pull() below does
      _get () {
        let parsedStore
        try {
          parsedStore = JSON.parse(window.localStorage.getItem(storeName))
        } catch {}
        if (!parsedStore) {
          return this._create()
        }
        return parsedStore
      },

      // Private method; not to be used outside of this class
      _set (items) {
        if (items !== undefined && Array.isArray(items)) {
          try { // Mobile Safari in private mode will throw an error
            window.localStorage.setItem(storeName, JSON.stringify(items))
            initialized[storeName] = true
          } catch {}
        } else {
          throw new Error('value of _set(value) must be an array; ' +
            'note that _set() should never be called directly, use push() instead')
        }
      },

      // No public functions (those below this line) should directly access window.localStorage
      // Note that pull() alters the value of the array in localStorage so that any subsequent pull
      //    does not return the same result (excusing any push sequences that may return the array
      //    to the same state)
      pull (count = 1) {
        if (count >= 1) {
          const store = this._get()
          const items = store.slice(0, count)
          this._set(store.slice(count))
          return items
        }
        return []
      },

      push (item, requeue = false) {
        if (item !== undefined) {
          if (Array.isArray(item)) {
            if (process.env.NODE_ENV === 'development' ||
              process.env.NODE_ENV === 'test') { // required; there is a unit test that checks this warning fires
              console.warn('Arrays are not unpacked when pushed into EventStore;' +
                'value will be stored as an Array')
            }
          }
          const items = this._get()
          items.push(item)
          this._set(items)

          if (!requeue) this._pushed = this._pushed + 1
        }
      },

      transmitted () {
        this._transmitted = this._transmitted + 1
      },

      length () {
        return this._get().length
      },

      clear () {
        this._create()
      },

      delete () {
        this._delete()
        initialized[storeName] = false
      }
    }
  }

  return stores[storeName]
}

export default (storeName, overwriteOnStart = false) => {
  const store = eventStoreClass(storeName)
  if (overwriteOnStart && !initialized[storeName]) store.clear()

  return store
}

/* globals loggerStoreBaseName, loggerSendInterval, loggerSendsPerCycle, loggerResetOnStart */
/* globals loggerUrl, loggerLevel, logLevels */
import eventStore from '../subscription_utils/eventStore'
import transmitter from './../subscription_utils/transmitter'

let logStore, logTransmitter
let initialized = false

export const log = (logLevel, event, eventData, ctx) => {
  if (!initialized) initialize()
  if (!logStore) logStore = eventStore(loggerStoreBaseName)
  const request = {
    url: loggerUrl,
    method: 'POST',
    headers: {},
    service_name: 'funnel',
    log_level: logLevels[logLevel],
    timestamp: new Date(),
    user_id: ctx?.userId,
    session_id: ctx?.sessionUUID,
    event,
    data: eventData
  }
  request.headers['Content-Type'] = 'application/json'

  if (loggerUrl && loggerLevel >= logLevel && loggerLevel !== logLevels.OFF) {
    logStore.push(request)
  } else {
    if (process.env.NODE_ENV === 'development' && loggerLevel !== logLevels.OFF) {
      console.log(request)
    }
  }
}

export const initialize = () => {
  if (!initialized || !logStore || !logTransmitter) {
    if (!logStore) {
      logStore = eventStore(loggerStoreBaseName, loggerResetOnStart)
    }

    if (!logTransmitter) {
      logTransmitter = transmitter(logStore, loggerSendInterval, loggerSendsPerCycle)
      log(2, 'TRANSMITTER INITIALIZATION',
        {
          message: 'Transmitter started',
          store_name: loggerStoreBaseName,
          transmitterId: logTransmitter
        })
    }

    initialized = true
  }
}

export default {
  log,
  initialize
}

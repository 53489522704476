// Funnel Machine Selectors
// context getters
export const getPostFunnelExperienceIframe = state => state.context.postFunnelExperienceConfig?.iframe
export const getQuestionMachineServiceReference = state => state?.children?.questionMachine || state.context?.question_machine_service?.questionMachine
export const getProgressBarDisable = state => state.context?.interfaceOptions?.disableProgressBar
// state matchers
export const verifyFunnelMachineIsInLoading = state => state.matches('loading')
export const verifyFunnelMachineIsInIdle = state => state.matches('idle')
export const verifyFunnelMachineIsInErrorLoading = state => state.matches('errorLoading')
export const verifyFunnelMachineIsInErrorFinalizing = state => state.matches('errorFinalizing')
export const verifyFunnelMachineIsInPostFunnelExperience = state => state.matches('postFunnelExperience')
export const verifyQuestionMachineIsReady = state =>
  state.matches('started') || state.matches('finalizing') || state.matches('redirectionExit')
export const verifyShowFinalIntermission = state => state.matches('finalizing') || state.matches('redirectionExit')

/* globals optimizelyKey, mixpanelKey, blueshiftKey */
export const track = (ctx, event, data) => {
  if (process.env.NODE_ENV !== 'test') {
    // Always
    if (mixpanelKey) {
      import('./tracking/mixpanel').then(({ mixpanelTrackEvent }) => {
        mixpanelTrackEvent(ctx, event, data)
      })
    }

    // Only on SA.com
    if (window.location.host.endsWith('smartasset.com') || window.location.host.endsWith('smrtast.com')) {
      if (optimizelyKey && ctx.sessionUUID) {
        import('./tracking/optimizely').then(({ optimizelyTrackEvent }) => {
          optimizelyTrackEvent(ctx, event)
        })
      }

      if (blueshiftKey === 'host') {
        import('./tracking/blueshift').then(({ blueshiftTrackEvent }) => {
          blueshiftTrackEvent(ctx, event, data)
        })
      }
    // Only offsite
    } else {
      if (window.dataLayer) {
        import('./tracking/gtm').then(({ gtmTrackEvent }) => {
          gtmTrackEvent(ctx, event)
        })
      }
    }
  }
}

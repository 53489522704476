import React, { useContext } from 'react'
import { StateMachineContext } from '../contexts/StateMachineProvider'
import { StyledButton } from './StyledButton'
import { StyledFunnel } from './StyledFunnel'
import PostFunnelFrame from './PostFunnelFrame'
import LoadingFunnel from './FunnelLoader'
import { lazy } from '../utils/utils'
import { useSelector } from '@xstate/react'
import {
  getPostFunnelExperienceIframe,
  verifyQuestionMachineIsReady,
  verifyFunnelMachineIsInLoading,
  verifyFunnelMachineIsInErrorLoading,
  verifyFunnelMachineIsInErrorFinalizing,
  verifyFunnelMachineIsInIdle,
  verifyFunnelMachineIsInPostFunnelExperience
} from '../utils/machine_utils/FunnelMachine.selectors'

const Funnel = () => {
  /**
   * A React hook for the funnelMachine with the questionMachine above
   * passed as an override
   */
  const stateMachineServices = useContext(StateMachineContext)
  const { funnelMachineService, questionMachineService } = stateMachineServices
  const { send } = funnelMachineService

  const postFunnelExperienceIframe = useSelector(funnelMachineService, getPostFunnelExperienceIframe)
  const questionMachineShouldBeReady = useSelector(funnelMachineService, verifyQuestionMachineIsReady)

  const isIdle = useSelector(funnelMachineService, verifyFunnelMachineIsInIdle)
  const isLoading = useSelector(funnelMachineService, verifyFunnelMachineIsInLoading)
  const isErrorLoading = useSelector(funnelMachineService, verifyFunnelMachineIsInErrorLoading)
  const isPostFunnelExperience = useSelector(funnelMachineService, verifyFunnelMachineIsInPostFunnelExperience)
  const isErrorFinalizing = useSelector(funnelMachineService, verifyFunnelMachineIsInErrorFinalizing)

  const QuestionPanel = lazy(() => import(/* webpackChunkName: "QuestionPanel" */'./QuestionPanel'))

  return (
    <>
      <React.Suspense fallback={<LoadingFunnel/>}>
        {(questionMachineShouldBeReady && questionMachineService)
          ? <QuestionPanel />
          : <StyledFunnel className='funnel'>
            {isIdle &&
          <StyledButton data-testid='idle_funnel' onClick={() => send('START')}>start</StyledButton>}

            {isErrorLoading &&
          <p className='message--error' data-testid='loading_funnel'>Error during loading. Reload to try again.</p>}

            {isPostFunnelExperience && postFunnelExperienceIframe &&
          <PostFunnelFrame iFrameUrl={postFunnelExperienceIframe}/>}

            {isErrorFinalizing &&
          <p className='message--loading' data-testid='loading_funnel'>
            An error occurred while finalizing the setup of your advisor contacts.
            Please check your email for your matches. We apologize for this inconvenience.
          </p>}

            {isLoading && <LoadingFunnel/>}
          </StyledFunnel>}
      </React.Suspense>
    </>
  )
}

export default Funnel

import broadcast from '../subscription_utils/broadcast'
import { initRetargetStore, popAnswerFromStore } from '../retargetStore'
import { track } from '../track'
import { evaluateBranching } from './QuestionMachine.utils'

export const fetchConfig = async (ctx) => {
  const { default: axios } = await import('axios')

  const configRes = await axios({
    url: ctx.configUrl,
    method: 'GET'
  })
  const config = configRes.data

  if (process.env.NODE_ENV === 'development') {
    const validator = await import('../validation_utils/validateConfig')
    const results = validator.validateConfig(config, ctx.configUrl)
    if (!(results?.valid)) throw new Error(results.errors)
  }

  const retargetStore = initRetargetStore()
  let pastAnswers
  // Get the past answers for the session from local storage
  if (retargetStore.length()) {
    pastAnswers = retargetStore.getAll()
  } else if (ctx.retargetSession && ctx.sessionUUID && config.retarget?.url) {
    try {
      // Get the past answers for the session from API
      const pastAnswersRes = await axios({
        url: `${config.retarget.url}?sessionUUID=${ctx.sessionUUID}`,
        method: 'GET'
      })
      try {
        // Transform the past answers response JSON
        const { default: jq } = await import(/* webpackChunkName: "jq" */ 'jq-in-the-browser')
        const query = jq(config.retarget.transform || '.')
        pastAnswers = query(pastAnswersRes.data)
      } catch (e) {}
    } catch (e) {}
  }

  config.question_groups = config.question_groups.map(g => {
    const groupData = g
    // Find a past answer for each question and add it to the machine context
    if (g.questions && pastAnswers) {
      groupData.questions = g.questions.map(q => {
        const { answerObject } = popAnswerFromStore(pastAnswers, q.question_key)
        return {
          ...q,
          pastAnswer: answerObject?.answer
        }
      })
    }
    return evaluateBranching(config.question_groups, groupData)
  })

  return config
}

export const awaitTransmissionsDone = (ctx) => {
  const cycleMS = 300
  const waitMS = 10000
  let cycleCount = Math.round(waitMS / cycleMS)

  // new implementation explicitly resolves when the transmissions_done property is truthy
  // or rejects when the cycleCount is less than 0
  return new Promise(resolve => {
    (function isLeadCaptured () {
      if (window.com_smartasset_smartadvisor_funnel?.transmissions_done) {
        broadcast('FUNNEL_DONE', {}, ctx)
        resolve()
      } else if (cycleCount > 0) {
        cycleCount--
        setTimeout(isLeadCaptured, cycleMS)
      } else {
        track(ctx, 'client-funnel-failure-finalize')
        throw new Error('event transmissions were not completed within allowed time')
      }
    })()
  })
}

export const ingestConfig = (ctx, response) => ({
  config_version: response?.data?.version,
  config_date: response?.headers && response.headers['Last-Modified']
    ? response.headers['Last-Modified']
    : response?.data?.modified_date,
  question_groups: response?.data?.question_groups,
  eventSubscriptions: response?.data?.eventSubscriptions,
  postFunnelExperienceConfig: response?.data?.postFunnelExperienceConfig,
  interfaceOptions: response?.data?.interfaceOptions
})

export const ingestUtmValues = (ctx) => {
  const utm = {}
  if (ctx.utmString) {
    const partnerParams = new URLSearchParams(window.location.search)
    utm.partner_campaign = partnerParams.get('utm_campaign') || ''
    utm.partner_source = partnerParams.get('utm_source') || ''
    utm.partner_content = partnerParams.get('utm_content') || ''
    utm.partner_medium = partnerParams.get('utm_medium') || ''
    utm.partner_term = partnerParams.get('utm_term') || ''
  }
  const params = new URLSearchParams(ctx.utmString || window.location.search)
  utm.id = params.get('utm_id') || ''
  utm.campaign = params.get('utm_campaign') || ''
  utm.source = params.get('utm_source') || ''
  utm.content = params.get('utm_content') || ''
  utm.medium = params.get('utm_medium') || ''
  utm.term = params.get('utm_term') || ''

  return { utm }
}

export const prefetchAssets = (ctx) => {
  setTimeout(() => {
    ctx.question_groups?.forEach(async qg => {
      if (qg.meta_content?.intermission?.prefetch) {
        for (const url of qg.meta_content.intermission.prefetch) {
          await fetch(url)
        }
      }
      if (qg.meta_content?.intermission?.iframe) {
        await fetch(qg.meta_content.intermission.iframe)
      }
    })
  }, 5000)
}

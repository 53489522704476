import {
  fetchConfig,
  ingestConfig,
  ingestUtmValues,
  prefetchAssets,
  awaitTransmissionsDone
} from '../utils/machine_utils/FunnelMachine.utils'
import broadcast from '../utils/subscription_utils/broadcast'
import { assign, createMachine, send } from 'xstate'
import { prepareResponses } from '../utils/machine_utils/QuestionMachine.utils'

export const funnelMachineDeclaration = {
  id: 'funnel',
  initial: 'loading',
  context: {
    config_version: '',
    config_date: '',
    responses: [],
    configUrl: '',
    userId: '',
    sessionUUID: '',
    startEnabled: true,
    question_groups: [],
    eventSubscriptions: {},
    postFunnelExperienceConfig: {},
    utmString: '',
    utm: {},
    retarget: {},
    retargetSession: true,
    interfaceOptions: {}
  },
  on: {
    START: {
      target: 'started',
      actions: 'logStart'
    },
    QUESTION_MACHINE_READY: {
      actions: ['saveQuestionMachineService']
    },
    FINALIZE_COMPLETE: [
      {
        target: 'postFunnelExperience',
        cond: 'enablePostFunnelExperience'
      },
      {
        target: 'redirectionExit'
      }
    ],
    FINALIZE_ERROR: {
      target: 'errorFinalizing'
    }
  },
  states: {
    loading: {
      invoke: {
        id: 'fetchConfig',
        src: 'fetchConfig',
        onDone: [{
          target: 'idle',
          actions: ['ingestConfig', 'loadUtmValues', 'prefetchAssets'],
          cond: 'startLandingEnabled'
        },
        {
          target: 'autoStarting',
          actions: ['ingestConfig', 'loadUtmValues', 'prefetchAssets']
        }],
        onError: {
          target: 'errorLoading',
          actions: ['logConfigError']
        }
      }
    },
    errorLoading: {
      type: 'final'
    },
    idle: {},
    autoStarting: {
      entry: 'startQuestions'
    },
    started: {
      invoke: {
        id: 'questionMachine',
        src: 'questionMachine',
        data: ctx => ({
          eventSubscriptions: ctx.eventSubscriptions,
          question_groups: ctx.question_groups,
          responses: ctx.responses,
          autofocus_enabled: false, // this invocation happens only on first question; do not autofocus input on first question
          userId: ctx.userId,
          sessionUUID: ctx.sessionUUID,
          utm: ctx.utm
        }),
        onDone: [
          {
            target: 'loading',
            actions: ['changeConfigUrl', 'saveResponses'],
            cond: 'isConfigChanging'
          },
          {
            target: 'finalizing',
            actions: 'saveResponses'
          }
        ]
      }
    },
    finalizing: {
      entry: ['logFinalize'],
      invoke: {
        id: 'awaitTransmissionsDone',
        src: 'awaitTransmissionsDone',
        onDone: [
          {
            target: 'postFunnelExperience',
            cond: 'enablePostFunnelExperience'
          },
          {
            target: 'redirectionExit'
          }
        ],
        onError: {
          target: 'errorFinalizing'
        }
      }
    },
    redirectionExit: {
      type: 'final'
    },
    postFunnelExperience: {
      type: 'final'
    },
    errorFinalizing: {
      type: 'final'
    }
  }
}

export const funnelMachineOptions = {
  services: {
    fetchConfig: async (ctx) => fetchConfig(ctx),
    awaitTransmissionsDone: ctx => awaitTransmissionsDone(ctx)
  },
  actions: {
    loadUtmValues: assign((ctx) => ingestUtmValues(ctx)),
    startQuestions: send('START'),
    saveResponses: assign({
      responses: (ctx, event) => ([
        ...ctx.responses,
        ...prepareResponses(event.data.question_groups)
      ])
    }),
    // We save the question machine instance in the context because when the service is
    //  done the machine reference that is normally found on state.children is gone, but
    //  we'd like to keep it around even if the machine has finished its purpose in order
    //  to let the question panel linger
    saveQuestionMachineService: assign({ question_machine_service: (ctx, event, meta) => meta.state.children }),
    ingestConfig: assign((ctx, event) => ingestConfig(ctx, event)),
    changeConfigUrl: assign((ctx, event) => ({
      configUrl: event.data.targetConfigUrl
    })),
    prefetchAssets: (ctx) => prefetchAssets(ctx),
    logStart: (ctx) => broadcast('FUNNEL_START', {}, ctx),
    logConfigError: (ctx) => broadcast('CONFIG_ERROR', {
      config_filename: ctx.configUrl
    }, ctx),
    logFinalize: (ctx) => {
      const sessionId = window.com_smartasset_smartadvisor_funnel?.leaduser_session_id
      broadcast('FUNNEL_FINALIZE', {
        session_id: sessionId > 0 ? sessionId : -1,
        valid_lead: sessionId > 0
      }, ctx)
    }
  },
  guards: {
    startLandingEnabled: (ctx) => ctx.startEnabled,
    enablePostFunnelExperience: (ctx) => ctx.postFunnelExperienceConfig?.iframe,
    isConfigChanging: (ctx, event) => !!event.data?.targetConfigUrl
  }
}

export const funnelMachine = createMachine(funnelMachineDeclaration, funnelMachineOptions)

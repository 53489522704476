/* globals enableXStateInspector */
import React from 'react'
import Logger from './utils/logger/logger'
import ReactDOM from 'react-dom'
import Funnel from './components/Funnel'
import { StateMachineProvider } from './contexts/StateMachineProvider'

Logger.initialize()

let rootElement = document.getElementById('smartadvisor-captivate-funnel')
if (!rootElement) rootElement = document.getElementById('sa-funnel_react-app')
if (!rootElement) {
  rootElement = document.createElement('div')
  rootElement.id = 'smartadvisor-captivate-funnel'
  rootElement.dataset.config = document.currentScript?.dataset?.config || ''
  rootElement.dataset.user_identifier = document.currentScript?.dataset?.user_identifier || ''
  rootElement.dataset.sessionUuid = document.currentScript?.dataset?.sessionUuid || ''
  rootElement.dataset.options = document.currentScript?.dataset?.options || ''
  rootElement.dataset.utms = document.currentScript?.dataset?.utms || ''
  document.currentScript.insertAdjacentElement('afterend', rootElement)
}

(async () => {
  // XState inspector doesn't run if started after the state machines are rendered
  if (enableXStateInspector) {
    const { inspect } = await import(
      /* webpackChunkName: "xStateInspect" */
      '@xstate/inspect'
    )
    inspect({
      iframe: false
    })
  }

  ReactDOM.render(
    <StateMachineProvider attributes={rootElement.dataset}>
      <Funnel />
    </StateMachineProvider>
    , rootElement)
})()

import { styled } from '../utils/stitches.config'

export const StyledButton = styled('button', {
  padding: '$2',
  margin: '$3 0',
  border: '1px solid $purple',
  color: '$purple',
  backgroundColor: '$purpleLight',
  fontSize: '$1',
  textTransform: 'uppercase',
  borderRadius: '$1',
  cursor: 'pointer',

  '&[disabled]': {
    backgroundColor: '$grayLight',
    border: '$grayMedium',
    color: '$gray'
  }
})

/* globals retargetStoreBaseName */
import { eventStoreClass } from './subscription_utils/eventStore'

let retargetStore

export const popAnswerFromStore = (store, questionKey) => {
  const pastAnswerIndex = store.findIndex((storedAnswer) => storedAnswer.questionName === questionKey)
  let answerObject

  if (typeof pastAnswerIndex === 'number' && pastAnswerIndex >= 0) {
    answerObject = store[pastAnswerIndex]
    store.splice(pastAnswerIndex, 1)
  }

  return { store, answerObject }
}

function removeQuestionFromRetargetStore (questionKey) {
  const parsedStore = this._get()
  const { store } = popAnswerFromStore(parsedStore, questionKey)
  this._set(store)
}

export const initRetargetStore = () => ({
  ...eventStoreClass(retargetStoreBaseName),
  remove: removeQuestionFromRetargetStore,
  getAll: function () {
    return this._get()
  }
})

export const storeForRetargeting = (event) => {
  retargetStore = retargetStore || initRetargetStore()
  retargetStore.remove(event.data.id)
  retargetStore.push({
    questionName: event.data.id,
    answer: event.data.value
  })
}

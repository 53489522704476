import { track } from '../track'

const transmitter = (store, sendInterval, sendsPerCycle) => {
  return {
    id: -1,
    store,
    sendsPerCycle,
    sendInterval
  }
}

const send = async (t) => {
  const requests = t.store.pull(t.sendsPerCycle)
  await Promise.all(requests.map(async request => {
    const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios')
    axios(request)
      .then(async response => {
        if (process.env.NODE_ENV === 'development') {
          console.log(`Success: ${response.status}: ${JSON.stringify(request)}`)
        }

        const sessionCtx = { sessionUUID: request.sessionUUID }
        if (request.url.toLowerCase().includes('leadcapture/advisormatch')) {
          // TODO Remove FALC specific handling for response to advisorMatch
          if (response?.data?.sessionId) {
            track(sessionCtx, 'client-funnel-matches-received', response.data)

            // Store sessionId for later use in leadCapture
            window.com_smartasset_smartadvisor_funnel = window.com_smartasset_smartadvisor_funnel || {}
            window.com_smartasset_smartadvisor_funnel.leaduser_session_id = response.data.sessionId
          }

          // Robo-advisor matches returned
          window.com_smartasset_smartadvisor_funnel.robos = response.data?.matches?.includes('listing--roboadvisor')

          // Store number of matches for fireLeadEvent
          let numberOfMatches = response.data.matches
          if (typeof numberOfMatches === 'string') {
            numberOfMatches = JSON.parse(response.data.matches)
          }
          window.com_smartasset_smartadvisor_funnel.number_of_matches = numberOfMatches.length
        } else if (request.url.toLowerCase().includes('leadcapture/leadcapture')) {
          // TODO Remove FALC specific handling for response to leadCapture
          track(sessionCtx, 'client-funnel-lead-captured', response.data)

          window.com_smartasset_smartadvisor_funnel = window.com_smartasset_smartadvisor_funnel || {}
          window.com_smartasset_smartadvisor_funnel.transmissions_done = true
        } else if (request.url.toLowerCase().includes('falcfunnelsession/answer')) {
          // TODO Remove FALC specific handling for asset tier
          const { default: qs } = await import(/* webpackChunkName: "qs" */ 'qs')
          const data = qs.parse(request.data)
          if (data.questionName === 'currentSavings') {
            // Used by the GTM and Mixpanel handlers for providing special events to Marketing
            // for measuring the types of users attracted by the various SEM campaigns
            window.com_smartasset_smartadvisor_funnel.assetTier = data.answer
          }
        }
        t.store.transmitted()
      })
      .catch(err => {
        const sessionCtx = { sessionUUID: request.sessionUUID }
        if (request.url.toLowerCase().includes('leadcapture/advisormatch')) {
          track(sessionCtx, 'client-funnel-error-matches-retrieval', err)
        }
        if (request.url.toLowerCase().includes('leadcapture/leadcapture')) {
          track(sessionCtx, 'client-funnel-error-lead-submission', err)
        }
        if (process.env.NODE_ENV === 'development') {
          console.error(`Failed: ${err.message} ${JSON.stringify(request)}`)
          return
        }
        t.store.push(request, true)
      })
  }))
  setTimeout(() => {
    send(t)
  }, t.sendInterval)
}

export default (store, sendInterval, sendsPerCycle) => {
  if (process.env.NODE_ENV !== 'test') {
    window.com_smartasset_smartadvisor_funnel = window.com_smartasset_smartadvisor_funnel || {}
    window.com_smartasset_smartadvisor_funnel.transmitters = window.com_smartasset_smartadvisor_funnel.transmitters || {}
    if (!window.com_smartasset_smartadvisor_funnel.transmitters[store.name]) {
      const t = transmitter(store, sendInterval, sendsPerCycle)
      setTimeout(() => {
        send(t).then() // Use of then() here avoids warnings about Promise returned being ignored
      }, t.sendInterval)

      window.com_smartasset_smartadvisor_funnel.transmitters[store.name] = t
    }
  }

  return store.name
}

/* globals logLevels */
import Logger from '../utils/logger/logger'
import { actions, assign, createMachine, send, sendParent } from 'xstate'
import broadcast from '../utils/subscription_utils/broadcast'
import {
  evaluateBranching,
  getCurrentQuestionGroupIndex,
  getNextQuestionGroup,
  prepareActors,
  updateMachineContext,
  updateQuestionGroupMeta
} from '../utils/machine_utils/QuestionMachine.utils'
import { storeForRetargeting } from '../utils/retargetStore'

export const questionMachineDeclaration = {
  id: 'question',
  initial: 'prep',
  preserveActionOrder: true,
  context: {
    question_group: null,
    accordion_active: false,
    autofocus_enabled: false,
    disableBackNavigation: false
    // values transferred into the context from the FunnelMachine invocation are
    // userId, sessionUUID, responses, question_groups, eventSubscriptions
  },
  states: {
    prep: {
      entry: ['setInitialQuestionGroup', 'readyToParent'],
      always: {
        target: 'editing'
      },
      exit: 'createActors'
    },
    intermission: {
      tags: ['intermission'], // tags are used to identify state value due to child state usage
      after: {
        INTERMISSION_DELAY: { target: 'editing' }
      },
      exit: 'completeIntermission'
    },
    editing: {
      tags: ['editing'], // tags are used to identify state value due to child state usage
      initial: 'active',
      states: {
        active: {
          initial: 'manualNav',
          always: [{
            target: 'delayedBillboard',
            cond: 'isTimedBillboard'
          },
          {
            target: 'loadingPanel',
            cond: 'isLoadingPanel'
          }],
          states: {
            hist: {
              type: 'history'
            },
            manualNav: {},
            autoNav: {
              invoke: {
                id: 'autoNav',
                src: ctx => callback => {
                  const exitAutoNavEvent = 'EXIT_AUTONAV'
                  const nextEvent = 'NEXT'
                  const id = setInterval(() => {
                    if (ctx.question_group.group_key === ctx.autoNavTargetGroup) {
                      callback(exitAutoNavEvent)
                    } else {
                      callback(nextEvent)
                    }
                  }, 0)

                  return () => clearInterval(id)
                },
                onDone: {
                  target: 'manualNav'
                }
              }
            }
          },
          on: {
            NEXT: [
              {
                actions: ['logEvent', 'requestNextQuestionGroup'],
                cond: 'isBillboard'
              }, { // used to trigger validation explicitly
                actions: 'logEvent',
                target: 'validating'
              }],
            EXIT_AUTONAV: {
              target: 'active.manualNav'
            }
          }
        },
        delayedBillboard: {
          after: {
            BILLBOARD_DELAY: {
              actions: ['logEvent', 'requestNextQuestionGroup']
            }
          }
        },
        loadingPanel: {
          after: {
            LOADING_PANEL_DELAY: {
              actions: ['logEvent', 'requestNextQuestionGroup']
            }
          }
        },
        validating: {
          always: {
            actions: ['startValidation'], // trigger VALIDATE event for all actors within the current question group
            target: 'active.hist'
          }
        }
      },
      always: {
        target: 'intermission',
        cond: 'canEnterIntermission'
      },
      on: {
        UPDATE_ANSWER: [
          {
            actions: [
              'logEvent',
              'storeForRetargeting',
              'updateResponses',
              'evaluateBranching',
              'requestNextQuestionGroup'
            ],
            cond: 'isSingleClick'
          },
          {
            actions: ['logEvent', 'storeForRetargeting', 'updateResponses', 'evaluateBranching']
          }
        ],
        UPDATE_ACTOR_ANSWERS: {
          actions: ['updateActorResponses']
        },
        VALID: { // if validation is successful in an actor - reset errors and prepare to go to the next question group
          actions: ['logEvent', 'resetErrors', 'enableAutoFocus', 'requestNextQuestionGroup'],
          cond: 'checkForErrors'
        },
        INVALID: { // inversely if validation fails, errors are set via resetErrors
          target: 'editing.active.manualNav',
          actions: ['logEvent', 'resetErrors']
        },
        // Can either move to the next question or to the "done"
        // state when the question machine is at the end of the questions array
        // in the machine context.
        // This event is no longer called from the component, NEXT is instead called to
        // trigger validation from the actors and then fire this event upon receiving the VALID event
        NEXT_QUESTION_GROUP: [
          {
            target: 'done',
            actions: ['broadcastQuestionResponseEvent', 'broadcastSubmitButtonClickEvent', 'accordionClose'],
            cond: 'isLastGroup'
          },
          {
            target: 'changeConfigUrl',
            actions: [
              'broadcastQuestionResponseEvent',
              'broadcastNextButtonClickEvent',
              'broadcastChangeConfigUrl'
            ],
            cond: 'isConfigChanging'
          },
          {
            target: 'editing.active.hist',
            actions: [
              'broadcastQuestionResponseEvent',
              'broadcastNextButtonClickEvent',
              'moveToNextQuestionGroup',
              'createActors',
              'accordionClose'
            ]
          }
        ],
        PREV: {
          target: 'editing.active.hist',
          actions: ['broadcastBackButtonClickEvent', 'moveToPrevQuestionGroup', 'accordionClose'],
          cond: 'canUseBackNavigation'
        },
        START_AUTONAV: {
          target: 'editing.active.autoNav',
          actions: ['storeAutoNavTarget', 'broadcastStartAutoNav']
        },
        AUTH_SUCCESS: {
          actions: 'broadcastAuthSuccess'
        },
        AUTH_FAILURE: {
          actions: 'broadcastAuthFailure'
        },
        ACCORDION_TOGGLE: {
          actions: ['accordionToggle', 'broadcastAccordionToggle']
        }
      }
    },
    // When we reach this state we want to package all the question_key and value
    // properties and send them back as an event payload for the funnel machine
    // it is tagged as editing in order to display the preload overlay on top of the existing
    // question machine component
    changeConfigUrl: {
      type: 'final',
      tags: ['editing'],
      data: ctx => ({
        question_groups: ctx.question_groups,
        targetConfigUrl: ctx.question_group.winningBranch.target_config_url
      })
    },
    done: {
      type: 'final',
      tags: ['editing'],
      data: ctx => ({
        question_groups: ctx.question_groups
      })
    }
  }
}

export const questionMachineOptions = {
  actions: {
    readyToParent: sendParent('QUESTION_MACHINE_READY'),
    storeForRetargeting: (ctx, event) => {
      storeForRetargeting(event)
    },
    enableAutoFocus: assign({
      autofocus_enabled: true
    }),
    accordionClose: assign({
      accordion_active: false
    }),
    accordionToggle: assign({
      accordion_active: (ctx) => !ctx.accordion_active
    }),
    setInitialQuestionGroup: assign({
      question_group: ctx => ctx.question_groups[0]
    }),
    createActors: assign({
      // iterate through each question group and create an input machine for each question
      // these will fire events to the question machine
      question_group: (ctx, event) => prepareActors(ctx.question_group, ctx.autofocus_enabled, event)
    }),
    updateResponses: assign((ctx, event) => updateMachineContext(ctx, event,
      (event, q) => ({
        ...q,
        value: event.data.value
      }))),
    resetErrors: assign((ctx, event) => updateMachineContext(ctx, event,
      (event, q) => ({
        ...q,
        errors: event.data.errors
      }))),
    completeIntermission: assign(
      ctx => updateQuestionGroupMeta(ctx,
        (g) => ({
          ...g,
          meta_content: {
            ...g.meta_content,
            intermission: {
              ...g.meta_content.intermission,
              complete: true
            }
          }
        })
      )
    ),
    evaluateBranching: assign(ctx => ({
      question_group: evaluateBranching(ctx.question_groups, ctx.question_group)
    })),
    moveToNextQuestionGroup: assign(ctx => {
      const nextQuestionGroup = getNextQuestionGroup(ctx.question_groups, ctx.question_group, ctx.winningBranch)
      return {
        question_group: nextQuestionGroup
      }
    }),
    moveToPrevQuestionGroup: assign(ctx => ({
      question_group: ctx.question_groups.find(g => g.group_key === ctx.question_group.prevQuestionGroupKey)
    })),
    broadcastChangeConfigUrl: ctx => {
      broadcast('CHANGE_CONFIG', {
        targetConfigUrl: ctx.question_group.winningBranch.target_config_url,
        group_key: ctx.question_group.group_key
      }, ctx)
    },
    broadcastQuestionResponseEvent: (ctx) => {
      ctx.question_group.questions?.forEach(q => {
        const data = {
          group_key: ctx.question_group.group_key,
          question_key: q.question_key,
          value: q.value,
          questionValue: q.questionValue,
          question_text: q.question_text,
          answer_type: q.answer_type,
          answer_choices: q.answer_choices?.map(ac => ({
            text: ac.description,
            value: ac.value,
            is_default: ac.default
          }))
        }
        broadcast('QUESTION_RESPONSE', data, ctx)
        Logger.log(logLevels.INFO, 'Response to question', data, ctx)
      })
    },
    broadcastNextButtonClickEvent: (ctx) => {
      const nextQuestionGroup = getNextQuestionGroup(ctx.question_groups, ctx.question_group, ctx.winningBranch)
      const data = {
        from: ctx.question_group.group_key,
        to: nextQuestionGroup?.group_key,
        questions: ctx.question_group.questions?.map(q => ({
          question_key: q.question_key,
          value: q.value
        }))
      }
      broadcast('NEXT_BUTTON_CLICK', data, ctx)
      Logger.log(logLevels.TRACE, 'Next button click', data, ctx)
    },
    broadcastSubmitButtonClickEvent: (ctx) => {
      const data = {
        from: ctx.question_group.group_key,
        questions: ctx.question_group.questions?.map(q => ({
          question_key: q.question_key,
          value: q.value
        }))
      }
      broadcast('SUBMIT_BUTTON_CLICK', data, ctx)
      Logger.log(logLevels.TRACE, 'Submit button click', data, ctx)
    },
    broadcastBackButtonClickEvent: (ctx, event) => {
      const data = {
        ...event,
        from: ctx.question_group.group_key,
        to: ctx.question_group.prevQuestionGroupKey
      }
      broadcast('BACK_BUTTON_CLICK', data, ctx)
      Logger.log(logLevels.TRACE, 'Back button click', data, ctx)
    },
    broadcastAccordionToggle: (ctx, event) => {
      broadcast('ACCORDION_TOGGLE', event.data, ctx)
      Logger.log(logLevels.TRACE, 'Accordion click/toggle', event, ctx)
    },
    broadcastAuthSuccess: (ctx, event) => {
      broadcast('AUTH_SUCCESS', event.data, ctx)
    },
    broadcastAuthFailure: (ctx, event) => {
      broadcast('AUTH_FAILURE', event.data, ctx)
    },
    // Using pure actions in order to send the same event to multiple actors
    // see: https://xstate.js.org/docs/guides/actions.html#pure-action for more details
    startValidation: actions.pure(ctx =>
      ctx.question_group.questions?.map(q => actions.send('VALIDATE', { to: q.ref }))
    ),
    // Update the answers of an actor(s) running within the question machine
    // currently only works for text field machines
    updateActorResponses: actions.pure((ctx, event) => {
      const { events: actorEvents } = event?.data
      const actorActions = []
      for (const actorEvent of actorEvents) {
        const questionGroup = ctx.question_groups.find(g => g.group_key === actorEvent.groupKey)
        if (questionGroup) {
          const question = questionGroup.questions?.find(q => q.question_key === actorEvent.id)
          if (question && question?.ref) {
            actorActions.push(actions.send({
              type: 'CHANGE',
              value: actorEvent.value
            }, { to: question.ref }))
          }
        }
      }
      return actorActions
    }),
    requestNextQuestionGroup: send('NEXT_QUESTION_GROUP'),
    storeAutoNavTarget: assign({
      autoNavTargetGroup: (ctx, event) => event.targetGroup
    }),
    logEvent: (ctx, event) => {
      Logger.log(logLevels.TRACE, 'General QuestionMachine event', {
        event
      }, ctx)
    }
  },
  guards: {
    isLastGroup: ctx => getCurrentQuestionGroupIndex(ctx.question_groups, ctx.question_group) === ctx.question_groups.length - 1,
    canUseBackNavigation: ctx => (ctx.question_group.prevQuestionGroupKey && !ctx.question_group.disableBackNavigation),
    canEnterIntermission: ctx => (ctx.question_group?.meta_content?.intermission && !ctx.question_group?.meta_content?.intermission?.complete),
    checkForErrors: ctx => {
      let errorFree = true
      ctx.question_group.questions?.forEach(q => {
        // we are checking each actor within the current question group for a valid state
        // using tags instead of actual state names for simplicity
        if (!q.ref.state.hasTag('valid')) {
          errorFree = false
        }
      })
      return errorFree
    },
    isBillboard: ctx => !!ctx.question_group.billboard,
    isTimedBillboard: ctx => !!ctx.question_group?.billboard?.delay,
    isLoadingPanel: ctx => !!ctx.question_group?.loadingPanel,
    isConfigChanging: ctx => !!ctx.question_group?.winningBranch?.target_config_url,
    isSingleClick: ctx => !!ctx.question_group?.meta_content?.is_single_click
  },
  delays: {
    INTERMISSION_DELAY: ctx => ctx.question_group?.meta_content?.intermission?.delay_time || 500,
    BILLBOARD_DELAY: ctx => ctx.question_group?.billboard?.delay,
    LOADING_PANEL_DELAY: ctx => ctx.question_group?.loadingPanel?.delay
  }
}

export const questionMachine = createMachine(questionMachineDeclaration, questionMachineOptions)

export default async function ValidateField (validationSchema, id, value) {
  // field machine assumes a validation flow, this skips questions without validations
  if (!validationSchema) return true
  // run validation for particular question against its schema in Yup,
  // abortEarly indicates whether subsequent tests should also run after an error in a previous one
  // returns an errors array after each async test resolves for a question, which is passed to the question machine context
  return await validationSchema[id].validate(value, { abortEarly: true }).catch(({ message, inner }) => {
    throw inner.length === 0
      ? message
      : inner.reduce((acc, message) => [...acc, message], [])
  })
}

/* globals sessionUuidCookieName */
import React from 'react'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
import { track } from './track'
import { getCookie, setCookie } from './cookieUtils'

/*
This function is lifted from the following github comment:
https://github.com/fuse-box/fuse-box/issues/1646#issuecomment-572242548

and avoids the following async error:
|   Element type is invalid. Received a promise that resolves to: [object Promise].
|   Lazy element type must resolve to a class or function.

Let's keep an eye on future updates of React and/or WebPack in case this is addressed.
 */
export const lazy = (componentImportFn) => React.lazy(async () => {
  const obj = await componentImportFn()
  return typeof obj.default === 'function' ? obj : obj.default
})

export const camelize = (str) => str.replace(/^([A-Z])|[\s-_]+(\w)/g,
  (_, p1, p2) => p2 ? p2.toUpperCase() : p1.toLowerCase())

// See README for info on runCustomAction
export const runCustomAction = (ctx, eventType, data) => {
  if (window && window.com_smartasset_smartadvisor_funnel) {
    const customAction = window.com_smartasset_smartadvisor_funnel[eventType]

    if (customAction) {
      customAction(ctx, eventType, data)
    }
  }

  track(ctx, eventType, data)
}

export const transformAttributes = attributes => {
  let retarget = true
  let {
    user_identifier: userId,
    sessionUuid: sessionUUID,
    config: configUrl,
    options,
    utms: utmString,
    disableSessionCookie
  } = attributes

  track(
    { sessionUUID },
    'client-funnel-uuid-loaded',
    { user_identifier: userId, sessionUuid: sessionUUID, config: configUrl, options, utms: utmString }
  )

  // Check for a UUID value already in window.com_smartasset_smartadvisor_funnel
  if (!sessionUUID && window.com_smartasset_smartadvisor_funnel?.sessionUUID) {
    sessionUUID = window.com_smartasset_smartadvisor_funnel.sessionUUID
  }
  // Check for UUID in cookies
  if (!sessionUUID && !disableSessionCookie) {
    sessionUUID = getCookie(sessionUuidCookieName)
  }
  // Regenerate if not a value UUID
  if (!sessionUUID || !uuidValidate(sessionUUID)) {
    sessionUUID = uuidv4()
    retarget = false
  }
  // Save UUID as cookie
  if (!disableSessionCookie) {
    setCookie(sessionUuidCookieName, sessionUUID)
  }

  window.com_smartasset_smartadvisor_funnel = window.com_smartasset_smartadvisor_funnel || {}
  window.com_smartasset_smartadvisor_funnel.sessionUUID = sessionUUID

  // Options
  const opts = options
    ? options.split(',').reduce((acc, opt) => {
      acc[opt] = true
      return acc
    }, {})
    : {}

  return {
    configUrl,
    userId,
    sessionUUID,
    options: opts,
    utmString,
    retargetSession: retarget
  }
}

import { assign, createMachine, sendParent } from 'xstate'
import ValidateField from '../utils/machine_utils/ValidationService'
import autofill from '../utils/machine_utils/AutofillService'

export const formInputDefinition = {
  id: 'formInput',
  initial: 'autofill',
  context: {
    id: null,
    groupKey: null,
    value: '',
    errors: [],
    autofill: {},
    validation_schema: {}
  },
  states: {
    autofill: {
      invoke: {
        id: 'autofillField',
        src: 'autofillField',
        onDone: {
          actions: [
            'setPromiseValue',
            'sendValueToParent'
          ],
          target: 'active'
        },
        onError: {
          target: 'active'
        }
      }
    },
    active: {
      type: 'parallel',
      states: {
        focus: {
          initial: 'unfocused',
          states: {
            focused: {
              on: {
                BLUR: {
                  target: 'unfocused'
                }
              }
            },
            unfocused: {
              on: {
                FOCUS: {
                  target: 'focused'
                }
              }
            }
          }
        },
        validation: {
          initial: 'pending',
          on: {
            CHANGE: {
              actions: [
                'setValue',
                'sendValueToParent'
              ]
            },
            VALIDATE: {
              actions: ['trimSpaces', 'sendValueToParent'],
              target: '.validating'
            }
          },
          states: {
            pending: {
              on: {
                START_VALIDATION: 'validating'
              }
            },
            validating: {
              invoke: {
                id: 'validate',
                src: 'validateField',
                onDone: [{
                  target: 'valid',
                  actions: 'clearErrors',
                  cond: 'inputNotChanged'
                },
                {
                  target: 'pending',
                  actions: 'clearErrors'
                }],
                onError: {
                  target: 'invalid',
                  actions: 'setErrors'
                }
              }
            },
            valid: {
              tags: ['valid'],
              type: 'final',
              entry: 'clearErrorsToParent',
              on: {
                FOCUS: 'pending'
              }
            },
            invalid: {
              type: 'final',
              entry: 'sendErrorsToParent',
              on: {
                FOCUS: 'pending'
              }
            }
          }
        }
      }
    }
  }
}

export const formInputOptions = {
  services: {
    validateField: ctx => ValidateField(ctx.validation_schema, ctx.id, ctx.value),
    autofillField: ctx => autofill(ctx.autofill, ctx.value)
  },
  actions: {
    clearErrors: assign(() => ({
      errors: []
    })),
    clearErrorsToParent: sendParent(ctx => ({
      type: 'VALID',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        errors: []
      }
    })),
    setErrors: assign((ctx, event) => ({
      errors: [event.data]
    })),
    sendErrorsToParent: sendParent(ctx => ({
      type: 'INVALID',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        errors: ctx.errors
      }
    })),
    setValue: assign({
      value: (ctx, event) => event.value
    }),
    setPromiseValue: assign({
      value: (ctx, event) => event.data
    }),
    trimSpaces: assign({
      value: (ctx, event) => ctx.value.trim()
    }),
    sendValueToParent: sendParent(ctx => ({
      type: 'UPDATE_ANSWER',
      data: {
        id: ctx.id,
        groupKey: ctx.groupKey,
        value: ctx.value
      }
    }))
  },
  guards: {
    inputNotChanged: (ctx, event) => (!ctx.validation_schema || ctx.value === event.data)
  }
}

export const formInputMachine = createMachine(formInputDefinition, formInputOptions)
